@import '../../../styles/propertySets.css';

.root {
  width: 100%;
  height: fit-content;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}

.fandomNavigationContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
}

.fandomNavigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.fandomLink {
  margin: 0px 8px;
  padding: 4px 8px;
  border-radius: 8px;

  & > a {
    color: var(--fave-color-primary-default);
    text-decoration: none;
  }
}

.activeFandomLink {
  margin: 0px 8px;
  padding: 4px 8px;
  border-radius: 12px;
  background-color: var(--fave-color-primary-default);

  & > a {
    color: var(--fave-color-white);
    text-decoration: none;
  }
}

.fandomHeroImageWrapper {
  width: 100%;
  // height: 420px;
}

.fandomHeroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.fandomInfo {
  // position: absolute;
  // padding: 16px;
  // width: 92%;
  // border-radius: 8px;
  // left: 50%;
  // bottom: 36px;
  // transform: translateX(-50%);

  // @media (--viewportMedium) {
  //   left: 36px;
  //   width: fit-content;
  //   transform: translateX(0%);
  // }

  // @media (min-width: 1501px) {
  //   left: 8vw;
  // }
}

.fandomBanner {
  // width: max-content;
  // text-align: center;
  // transform: rotate(5deg);
  // border-radius: 8px;
  // font-family: var(--fave-font-headline);
  // font-weight: 700;
  // font-size: clamp(2rem, 2vw, 4rem);
  // padding: 32px 64px;
  // background: linear-gradient(180deg, #f4c343 0%, #fade78 100%);
  /** marketplaceH1FontStyles*/  
 font-family: 'Greycliff';
    font-size: 40px;
    font-weight: 800;
    line-height: 46px;
    letter-spacing: -0.01em;
/** end */
  color: var(--fave-color-black-s900);
  padding: 36px
}

.fandomText {
  font-family: var(--fave-font-default);
  font-size: clamp(1.11rem, 1.5vw, 1.3rem);
  margin-top: 16px;
  line-height: 1.2;
}
