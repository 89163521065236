@import '../../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 12px;
  padding-bottom: 6px;
  border: none;

  @media (--viewportMedium) {
    padding: 3px 0 29px 12px;
    padding: 4px 0 4px 12px;
  }
}

.list {
  margin: 0;
  display: flex;
  gap: 12px;

 &>li {
  &input[type=checkbox] {
    display: none;
  }
 } 
}
.fieldCheckbox {
  position: relative;
  
  top: 0;
}

.item {
  padding: 2px 0;
  position: relative;
  left: -16px;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}
