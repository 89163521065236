@import '../../../styles/propertySets.css';

.root {
}

.icon {
  position: relative;
  margin-left: 12px;
  transition: all 0.2s ease-out;

  @media (--viewportMedium) {
    margin-left: 36px;
  }
}

.iconArrowAnimation {
  transform: rotate(-180deg);
}

.menuLabel {
  /** fave-copy-default-regular /*
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */

  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 160px;
  height: auto;
  min-height: 0;

  padding: var(--marketplaceButtonSmallDesktopPadding);

  border: var(--fave-card-border);
  border-radius: 16px;

  white-space: nowrap;

  &:focus,
  &:hover {
    background-color: var(--fave-color-purple-s50);
    text-decoration: none;
  }
}

.menuContent {
  margin-top: 7px;
  padding-top: 24px;
  padding-bottom: 24px;
  min-width: 300px;
  border-radius: 16px;
  z-index: 1000;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--matterColorDark);
}

.menuHeading {
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
  margin-top: 24px;
  margin-left: 30px;
  margin-right: 30px;
}

.menuItem {
  /**marketplaceListingAttributeFontStyles*/  
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
/** end */
  color: var(--matterColor);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }

  &:disabled {
    color: var(--matterColorAnti);
    cursor: default;
  }
  &:disabled:hover .menuItemBorder {
    width: 0;
  }
}

.clearMenuItem {
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}
