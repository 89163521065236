@keyframes translateBack {
  from {
    transform: translateX(14px);
  }

  to {
    transform: translate(0);
  }
}

.root {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1px 1px 1px 1px;
  gap: 10px;

  border: 1px solid var(--fave-color-primary-s500);
  width: 39px;
  height: 20px;

  /* Gradient/Purple */

  border-radius: 99px;
}

.rootChecked {
  background: linear-gradient(
    83.64deg,
    var(--fave-color-primary-s500),
    var(--fave-color-primary-s400) 89.97%
  );
}

.circle {
  box-sizing: content-box;
  position: relative;
  left: -2px;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--fave-color-primary-s500);

  top: 0;
  width: 19px;
  height: 19px;

  background: var(--color-white-pure);
  border-radius: calc(19px * 2);
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  left: -2px;

  &:hover {
    cursor: pointer;
  }
}

.circleTranslateBack {
  animation-name: translateBack;
  animation-duration: 0.1s;
}

.circleChecked {
  transform: translateX(14px);
  transition: transform 0.1s linear;
}

.checkBox {
  position: absolute;

  width: 47.92px;
  height: 20px;
  border-radius: 99px;

  opacity: 0;
  z-index: 9999;

  &:hover {
    cursor: pointer;
  }
}
